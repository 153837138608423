.msgIMG .close {
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
}
.rootsend {
  flex-grow: 1;
  position: relative;
  padding: 8px;
  display: flex;
  flex-direction: row-reverse;
}

.rootsend:hover {
  background-color: #1f2436;
}

.msgIMG:hover {
    opacity: 0.7;
}
.modal {
    display: none;
    position: fixed;
    z-index: 1300;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7);
}
#caption,
.modalContent {
    margin: auto;
    display: block;
    width: auto;
    height: auto;
    max-width: 1200px;
}
#caption {
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}
#caption,
.modalContent {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}
  @-webkit-keyframes zoom {
    from {
      -webkit-transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  @-moz-keyframes zoom {
    from {
      -moz-transform: scale(0);
    }
    to {
      -moz-transform: scale(1);
    }
  }
  @-o-keyframes zoom {
    from {
      -o-transform: scale(0);
    }
    to {
      -o-transform: scale(1);
    }
  }
  @-ms-keyframes zoom {
    from {
      -ms-transform: scale(0);
    }
    to {
      -ms-transform: scale(1);
    }
  }
  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: 300;
  }
  .close:focus,
  .close:hover {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  @media only screen and (max-width: 700px) {
    .modalContent {
      width: 100%;
    }
  }

  .download {
    position: absolute;
    color: #f1f1f1;
    top: 15px;
    left: 35px;
    font-size: 40px;
    font-weight: 300;
  }
  .download:focus,
  .download:hover {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }